import store from '@/state/store'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/home'),
  },
  {
    path: '/',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: () => {
            store.dispatch('USER_DATA').then((res) => {
              let firstRoute = 'users';

              if (res.permissions.users) firstRoute = 'users';
              else if (res.permissions.notifications) firstRoute = 'notifications';
              else if (res.permissions.banners) firstRoute = 'banners';
              else if (res.permissions.images) firstRoute = 'product-image';
              else if (res.permissions.paymentRules) firstRoute = 'payment-settings';
              else if (res.permissions.campaigns) firstRoute = 'campaigns/delivery';
              else if (res.permissions.permissions) firstRoute = 'permissions';
              else if (res.permissions.settings) firstRoute = 'settings';

              return firstRoute;
            }).catch(() => {
              return 'users'
            })
          } })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('AUTH_LOGOUT')
        // const authRequiredOnPreviousRoute = routeFrom.matched.some(
        //   (route) => route.push('/login')
        // )

        next({ name: 'login' });

        // Navigate back to previous page, or home as a fallback
        // next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/users',
    name: 'users',
    meta: { authRequired: true },
    component: () => import('./views/users/Users')
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: { authRequired: true },
    component: () => import('./views/notifications/Notifications')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { authRequired: true },
    component: () => import('./views/settings/Settings')
  },
  {
    path: '/payment-settings',
    name: 'payment-settings',
    meta: { authRequired: true },
    component: () => import('./views/settings/PaymentSettings')
  },
  {
    path: '/payment-settings-b2b',
    name: 'payment-settings-b2b',
    meta: { authRequired: true },
    component: () => import('./views/settings/PaymentSettingsB2b')
  },
  {
    path: '/product-limit-rules-b2b',
    name: 'product-limit-rules-b2b',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductLimitRulesB2b')
  },
  {
    path: '/department-limit-rules-b2b',
    name: 'department-limit-rules-b2b',
    meta: { authRequired: true },
    component: () => import('./views/products/DepartmentLimitRulesB2b')
  },
  {
    path: '/product-limit-rules',
    name: 'product-limit-rules',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductLimitRules')
  },
  {
    path: '/department-limit-rules',
    name: 'department-limit-rules',
    meta: { authRequired: true },
    component: () => import('./views/products/DepartmentLimitRules')
  },
  {
    path: '/product-quantity-discount',
    name: 'product-quantity-discount',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductQuantityDiscount')
  },
  {
    path: '/product-quantity-discount-b2b',
    name: 'product-quantity-discount-b2b',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductQuantityDiscountB2b')
  },
  {
    path: '/banners',
    name: 'banners',
    meta: { authRequired: true },
    component: () => import('./views/settings/Banners')
  },
  {
    path: '/product-image',
    name: 'product-image',
    meta: { authRequired: true },
    component: () => import('./views/settings/ProductImage')
  },
  {
    path: '/campaigns/delivery',
    name: 'campaigns-delivery',
    meta: { authRequired: true },
    component: () => import('./views/campaigns/Delivery')
  },
  {
    path: '/campaigns/department',
    name: 'campaigns-department',
    meta: { authRequired: true },
    component: () => import('./views/campaigns/DepartmentDiscount')
  },
  {
    path: '/campaigns/department-b2b',
    name: 'campaigns-department-b2b',
    meta: { authRequired: true },
    component: () => import('./views/campaigns/DepartmentDiscountB2b')
  },
  {
    path: '/campaigns/product',
    name: 'campaigns-product',
    meta: { authRequired: true },
    component: () => import('./views/campaigns/ProductDiscount')
  },
  {
    path: '/campaigns/product-b2b',
    name: 'campaigns-product-b2b',
    meta: { authRequired: true },
    component: () => import('./views/campaigns/ProductDiscountB2b')
  },
  {
    path: '/campaigns/coupons',
    name: 'campaigns-coupons',
    meta: { authRequired: true },
    component: () => import('./views/campaigns/Coupons')
  },
  {
    path: '/permissions',
    name: 'permissions',
    meta: { authRequired: true },
    component: () => import('./views/settings/Permissions')
  },
]
