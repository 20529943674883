import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import VueFilterDateFormat from 'vue-filter-date-format';
import VueCurrencyFilter from 'vue-currency-filter'
import router from './router/index'
import api from '@/services/api'

import "@/design/index.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import store from '@/state/store'
import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import money from 'v-money'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import App from './App.vue'

Vue.use(VueRouter)
Vue.use(vco)

Vue.config.productionTip = false

Vue.use(VueFilterDateFormat)
Vue.use(VueCurrencyFilter, {
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(money)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

Vue.use(Loading, {
  color: '#1c1798',
  width: 45,
  height: 45,
  canCancel: false,
})

Vue.prototype.$http = api;

api.interceptors.response.use(response => {
  return response;
}, error => {
  // eslint-disable-next-line no-console
  console.log(error);
  
  if (error.response && error.response.data && error.response.data.serviceOffline) {
    store.dispatch('AUTH_LOGOUT').then(() => {
      router.push('/?service=offline')
    })
  }

  return Promise.reject(error);
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
