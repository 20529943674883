import api from '@/services/api'

export default {
  login(user, password) {
    return api.post('/users/b2b/admin/auth', { user, password })
  },

  getData() {
    return api.get('/users/b2b/admin')
  },

  getStats() {
    return api.get('/users/stats')
  },
  
  getB2bUsers(page = 1, term = '') {
    let params = `?page=${page}`;
    if (term) {
      params += `&term=${term}`;
    }

    return api.get(`/users/b2b/list${params}`)
  },

  sendNotification(data) {
    return api.post('/users/b2b/notification', data);
  },

  removeUser(id) {
    return api.delete(`/users/b2b/${id}`);
  },

  exportB2bUsers(term) {
    let params = '?export=true';
    if (term) {
      params += `&term=${term}`;
    }

    return api({
      url: `/users/b2b/list${params}`,
      method: 'GET',
      responseType: 'blob',
    })
  },
}